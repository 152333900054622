import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { guidelineShape } from "../../lib/data_shapes";
import { stripHtml, isValidLink } from "../../lib/utils";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";

const keysToInclude = ["citation", "publicationDate", "isbn", "pmid", "sourceDocumentLink"];

const translatableKeys = ["version", "field", "source"];
const SourceOfRecommendation = ({ guideline }) => {
  const { t } = useTranslation();
  const getValue = useCallback(
    (key, value) => {
      switch (key) {
        case "subIntent":
        case "intent":
          return t(`tabs.${value}`);
        case "publicationDate":
        case "latestLiteratureSearchDate":
          return _.isEmpty(value)
            ? ""
            : value !== "not_reported"
            ? new Date(value).toISOString().slice(0, 10)
            : t(`recommendation.${value}`);
        case "gradingEvidenceMethod":
          if (_.includes(["notGraded", "grade"], value)) {
            return t(`recommendation.fieldValues.${key}.${value}`);
          } else {
            return value;
          }
        case "field":
          return _.map(value, (v) => t(`recommendation.fieldValues.${key}.${v}`)).join(", ");
        case "declarationOfInterest":
          return value ? t("labels.yes") : t("labels.no");
        case "guidelineGroupDetails":
          const plainText = stripHtml(value);
          if (plainText === "notReported" || plainText === "no") {
            return t(`labels.${value}`);
          }
          return <div dangerouslySetInnerHTML={{ __html: value }} />;
        default:
          if (_.includes(translatableKeys, key)) {
            return t(`recommendation.fieldValues.${key}.${value}`);
          }
          if (isValidLink(value)) {
            return (
              <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
            );
          }
          return value?.toString();
      }
    },
    [t]
  );

  const rows = useMemo(
    () =>
      _.reduce(
        guideline,
        (acc, value, label) => {
          const v = getValue(label, value);
          return keysToInclude.indexOf(label) !== -1 && !_.isEmpty(v?.toString())
            ? acc.concat({ label: t(`recommendation.${label}`), value: v })
            : acc;
        },
        []
      ),
    [guideline, getValue, t]
  );

  return (
    guideline && (
      <ExpandableCard title={t("labels.source_of_recommendation")}>
        <h2 className="font-semibold m-3">{guideline.title}</h2>
        <CustomTable rows={rows} />
      </ExpandableCard>
    )
  );
};

SourceOfRecommendation.propTypes = {
  guideline: guidelineShape,
};

export default SourceOfRecommendation;
