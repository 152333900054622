import "../../lib/externalImports";
import angular from "angular";
import "angular-cookies";
import "angular-sanitize";
import "angular-bootstrap/ui-bootstrap-tpls";
import "angular-translate";
import "angular-resource";
import "angular-ui-router";
import "angular-ui-select/select.min.js";
import "angular-ui-sortable/src/sortable";
import "angular-translate-loader-static-files/angular-translate-loader-static-files";
import "angular-datamaps/dist/angular-datamaps";

import "checklist-model/checklist-model";
import "angular-xeditable/dist/js/xeditable";
import "angular-xeditable/dist/css/xeditable.css";
import "@evidenceprime/isof-dx/scripts/isof-dx.scripts";
import "@evidenceprime/isof-tx/scripts/isof-tx.scripts";
import "d3/d3.min.js";

import "@evidenceprime/isof-dx/styles/isof-dx.main.css";
import "@evidenceprime/isof-tx/styles//isof-tx.main.css";

import "opentip/css/opentip.css";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";
import { useDidUpdateEffect } from "../../lib/utils";
import CustomTable from "../common/CustomTable";
import ExternalLink from "../common/ExternalLink";

class ISofDX {
  constructor(container, data) {
    this.container = container;
    this.data = data;
    angular.module("isofDxApp").value("isofData", this.data);
    angular.element(this.container).ready(() => {
      if (!angular.element(this.container).scope()) {
        return angular.bootstrap(this.container, ["isofDxApp"]);
      }
    });
  }

  rerender(container, data) {
    this.container = container;
    this.data = data;
    angular.element(this.container).removeData();
    angular.module("isofDxApp").value("isofData", this.data);
    return angular.bootstrap(this.container, ["isofDxApp"]);
  }
}

class ISofTX {
  constructor(container, data, onSavePlainLanguageStatement) {
    this.container = container;
    this.data = data;
    this.onSavePlainLanguageStatement = onSavePlainLanguageStatement;
    angular.module("isofTxApp").value("isofData", this.data);
    angular
      .module("isofTxApp")
      .value("onSavePlainLanguageStatement", this.onSavePlainLanguageStatement);
    return angular.element(this.container).ready(() => {
      return angular.bootstrap(this.container, ["isofTxApp"]);
    });
  }

  rerender(container, data, onSavePlainLanguageStatement) {
    this.container = container;
    this.data = data;
    this.onSavePlainLanguageStatement = onSavePlainLanguageStatement;
    angular.element(this.container).removeData();
    angular.module("isofTxApp").value("isofData", this.data);
    angular
      .module("isofTxApp")
      .value("onSavePlainLanguageStatement", this.onSavePlainLanguageStatement);
    return angular.bootstrap(this.container, ["isofTxApp"]);
  }
}

const ISof = ({ isofData, isDiagnostic }) => {
  const { t } = useTranslation();
  const [comparator, setComparator] = useState("index");
  useEffect(() => {
    if (isDiagnostic) {
      new ISofDX(
        "#isof-dx-content",
        isofData.comparatorIsofData
          ? isofData[comparator === "index" ? "indexIsofData" : "comparatorIsofData"]
          : isofData
      );
    } else {
      new ISofTX("#isof-tx-content", isofData, null);
    }
  }, [isDiagnostic, comparator, isofData]);

  useDidUpdateEffect(() => {
    if (isDiagnostic) {
      new ISofDX().rerender(
        "#isof-dx-content",
        isofData.comparatorIsofData
          ? isofData[comparator === "index" ? "indexIsofData" : "comparatorIsofData"]
          : isofData
      );
    } else {
      new ISofTX().rerender("#isof-tx-content", isofData, null);
    }
  }, [isDiagnostic, comparator, isofData]);

  return isDiagnostic ? (
    <div id="isof-dx-content" className="isof-dx container">
      {isofData.comparatorIsofData && (
        <div>
          <select onChange={(e) => setComparator(e.target.value)}>
            <option value="index">
              {t("labels.index_test")}: {isofData.indexIsofData.test}
            </option>
            <option value="comparator">
              {t("labels.comparator_test")}: {isofData.comparatorIsofData.test}
            </option>
          </select>
        </div>
      )}
      <diagnosis></diagnosis>
    </div>
  ) : (
    <div id="isof-tx-content" className="isof-tx container">
      <isof-table editable={false}></isof-table>
    </div>
  );
};

const ISofSection = ({ sofSupplemented, isofData, toggleRef, additionalEvidenceTableUrl }) => {
  const expandableRef = useRef(null);
  const { t } = useTranslation();

  return !_.isEmpty(isofData) ? (
    <ExpandableCard
      toggleRef={toggleRef}
      forwardRef={expandableRef}
      onTransitionEnd={() => {
        expandableRef.current.updateContainerHeight();
      }}
      className="mb-5"
      contentClassName="px-1"
      contentBg="bg-white"
      title={t("labels.iSof")}
    >
      {sofSupplemented && (
        <div className="mx-2 mb-6">
          {t("detailsOfRecommendationPage.sofSupplementedDescription")}
        </div>
      )}
      <ISof isofData={isofData} isDiagnostic={isofData.isDiagnostic} />
      <CustomTable
        className="flex-grow"
        rows={[
          !_.isEmpty(additionalEvidenceTableUrl)
            ? {
                label: t("labels.additionalEvidenceTableUrls"),
                value: _.map(_.compact(additionalEvidenceTableUrl), (url) => (
                  <div key={url}>
                    <ExternalLink href={url} label={url} />
                  </div>
                )),
              }
            : null,
        ]}
      />
    </ExpandableCard>
  ) : null;
};

ISofSection.propTypes = {
  isofData: PropTypes.object,
  sofSupplemented: PropTypes.bool,
  additionalEvidenceTableUrl: PropTypes,
};

ISofSection.defaultProps = {
  isofData: null,
  sofSupplemented: null,
};

export default ISofSection;
